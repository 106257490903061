import React, { useState } from "react";
import axios from "axios";
import "./Company.css";
import 신청확인버튼 from "../assets/images/reserve/신청확인버튼.png";

const EventCheck = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [reservation, setReservation] = useState(null);
  const [error, setError] = useState(null);

  const handleCheckReservation = async () => {
    try {
      const response = await axios.get(
        "https://k-dogs.co.kr/api/reservations/check",
        {
          params: { name, phone },
        }
      );
      setReservation(response.data);
      setError(null);
    } catch (error) {
      setError(
        error.response
          ? error.response.data.message
          : "예약 정보를 찾을 수 없습니다."
      );
      setReservation(null);
    }
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    return date.toLocaleString("ko-KR", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  return (
    <>
      <div className="bodytop-text">
        {/* <div className="sub-text">Contents Information</div>
        <div className="header-text">
          <span className="underline">K-Dog 스포츠</span>
          <span> 고양 참가신청</span>
        </div> */}
      </div>

      <div className="main">
        <div className="form-container">
          <h2>참가 신청 확인</h2>
          <div className="inputCotainer">
            <input
              type="name"
              placeholder="신청자명 입력"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <p></p>
            <input
              type="tel"
              placeholder="휴대폰 번호 입력"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="reserveDiv">
            <img
              className="reserveCheckButton"
              src={신청확인버튼}
              onClick={handleCheckReservation}
              alt="신청확인버튼"
            />
          </div>

          {error && (
            <div className="error-message">
              <p>{error}</p>
            </div>
          )}

          {reservation && (
            <div className="form-container">
              {/* <h3>예약 정보</h3>
            <p>이름: {reservation.name}</p>
            <p>이메일: {reservation.email}</p>
            <p>전화번호: {reservation.phone}</p>
            <p>행사: {reservation.event}</p>
            <p>지역: {reservation.region}</p>
            <p>예약일: {formatDate(reservation.date)}</p> */}

              <div
                style={{
                  fontSize: "16px",
                  color: "#333",
                  lineHeight: "1.6",
                  padding: "20px",
                  backgroundColor: "#f9f9f9",
                  borderRadius: "8px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  marginBottom: "50px",
                }}
              >
                <h2
                  style={{
                    fontSize: "28px",
                    fontWeight: "bold",
                    color: "#3366FF",
                  }}
                >
                  K-DOG SPORTS FESTIVAL
                  <div style={{ fontSize: "23px" }}>신청정보</div>
                </h2>

                {/* <p>
                캐니워킹에 참여하는 모든 분들이 안전하고 즐거운 시간을 보낼 수
                있도록 아래 주의 사항을 꼭 지켜주세요.
              </p> */}
                <ul
                  style={{
                    listStyleType: "disc",
                    paddingLeft: "20px",
                    textAlign: "left",
                  }}
                >
                  <li>이름: {reservation.name}</li>
                  <li>이메일: {reservation.email}</li>
                  <li>
                    전화번호:{" "}
                    {reservation.phone.replace(
                      /(\d{3})(\d{4})(\d{4})/,
                      "$1-$2-$3"
                    )}
                  </li>

                  <li>반려동물이름: {reservation.petname}</li>
                  <li>행사: {reservation.event}</li>
                  <li>지역: {reservation.region}</li>
                  <li>신청일: {formatDate(reservation.date)}</li>
                </ul>
                {/* <p style={{ fontWeight: "bold", color: "#3366FF" }}>
                안전한 페스티벌을 위해 모든 참가자분들의
                <div>협조를 부탁드립니다!</div>
              </p> */}
              </div>
            </div>
          )}
        </div>
        <div style={{ marginBottom: "100px" }}></div>
      </div>
    </>
  );
};

export default EventCheck;
