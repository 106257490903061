import React, { useState } from "react";
import "./Company.css";

import 캐니워킹규정 from "../assets/images/reserve/캐니워킹규정.jpg";
import 사전예약그림 from "../assets/images/reserve/사전예약그림_1029.png";

import { Link } from "react-router-dom";

const Reservation = () => {
  const [activeButton, setActiveButton] = useState("");
  const [showRegulation, setShowRegulation] = useState(false); // State to toggle visibility

  const handleClick = (url) => {
    window.open(url, "_blank");
  };

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const toggleRegulation = () => {
    setShowRegulation(!showRegulation); // Toggle the visibility
  };

  return (
    <>
      <div className="bodytop-text">
        {/* <div className="sub-text">Contents Information</div>
        <div className="header-text">
          <span className="underline">K-Dog 스포츠</span>
          <span> 고양 사전예약</span>
        </div> */}
      </div>
      <div className="main">
        <div className="image-section">
          <img
            src={사전예약그림}
            alt="프로그램"
            className="responsive-image-reserve"
          />

          <p
            style={{ marginTop: "8%", marginBottom: "8%" }}
            className="giftInfoText"
          >
            사전예약 혜택은 11월 15일 금요일까지
            <div>신청하신 분들에 한해 제공됩니다.</div>
          </p>
          {/* <p>이후 신청은 현장신청만 받습니다.</p> */}
          <div className="caniMoreInfo" onClick={toggleRegulation}>
            ▼ 캐니워킹 알아보기 ▼
            <div
              style={{ marginTop: "10px", fontSize: "0.7em", color: "blue" }}
            >
              Click
            </div>
          </div>

          {showRegulation && (
            <div className="regulationImageContainer">
              <img
                src={캐니워킹규정}
                alt="캐니워킹 규정"
                className="regulationImage"
              />
            </div>
          )}

          <Link to="/reserve/eventform">
            {/* 사전예약 */}
            {/* <img
              src={참가신청버튼}
              alt="프로그램"
              className="company-apply-button"
            /> */}
            <div className="applyButton"></div>
          </Link>
          <Link to="/reserve/eventcheck">
            {/* 사전예약확인 */}
            {/* <img
              src={참가신청확인하기버튼}
              alt="프로그램"
              className="company-apply-button"
            /> */}
            <div className="applyCheckButton"></div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Reservation;
