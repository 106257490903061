import React, { useEffect } from "react";
import "./Program.css";

// import 페스티벌프로그램안내 from "../assets/images/program/페스티벌프로그램안내.png";
import 페스티벌프로그램안내 from "../assets/images/program/페스티벌프로그램안내_일산_1029.png";
import 댕댕이올림픽 from "../assets/images/program/댕댕이올림픽.jpg";
import 콘테스트 from "../assets/images/program/사이즈자른콘테스트이미지.jpg";
import 프로그램_사전예약하기 from "../assets/images/program/프로그램_사전예약하기.png";

import 캐니워킹규정 from "../assets/images/program/캐니워킹규정.jpg";
import 상담존 from "../assets/images/program/상담존.png";
import 체험존 from "../assets/images/program/체험존.png";
import { Link, useLocation } from "react-router-dom";
import FullscreenModal from "./FullscreenModal";

const Program = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      // DOM이 완전히 렌더링된 후에 스크롤 이동을 시도합니다.
      setTimeout(() => {
        const targetElement = document.querySelector(hash);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }
      }, 0); // 또는 약간의 지연을 주기 위해 100ms로 설정할 수도 있음
    }
  }, [hash]);

  return (
    <>
      <div className="bodytop-text">
        {/* <div className="sub-text">Contents Information</div>
        <div className="header-text">
          <span className="underline">K-Dog 페스</span>
          <span>티벌 프로그램 안내</span>
        </div> */}
      </div>
      <div className="main">
        <table className="program-table">
          <tbody>
            <tr>
              <td colSpan="2" className="table-cell-image">
                <img
                  src={페스티벌프로그램안내}
                  alt="페스티벌프로그램안내"
                  className="responsive-image-top"
                />
              </td>
            </tr>
            <tr>
              <td className="table-cell">
                <img
                  src={댕댕이올림픽}
                  alt="댕댕이올림픽"
                  className="responsive-image"
                />
              </td>

              <td className="table-text">
                <h2 className="fixed-title">댕댕이 올림픽</h2>
                <ul>
                  <li>
                    <h3>
                      <span className="underline">캐니워킹</span>
                      <span>(Cani-Walking)대회</span>
                    </h3>
                    <p>
                      반려견이 리드줄과 하네스를 착용한 채, 보호자와 완벽한
                      호흡을 맞추며 함께 걷는 팀이 우승!
                    </p>
                  </li>

                  <li>
                    <Link to="/reserve/eventform">
                      {/* 사전예약 */}
                      {/* <img
                        src={사전예약버튼}
                        alt="프로그램"
                        className="company-apply-button"
                      /> */}
                      <img
                        src={프로그램_사전예약하기}
                        alt="프로그램_사전예약하기"
                        className="programCaniButton"
                      />
                    </Link>
                  </li>
                  <li>
                    <h3>
                      <span className="underline">댕댕런</span>
                      <span>(Run)</span>
                    </h3>
                    <p>
                      25m의 거리를 따라 달려, 가장 먼저 결승선에 도착하는
                      강아지가 우승!
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <p></p>
            <tr>
              <td className="table-cell">
                <img
                  src={콘테스트}
                  alt="콘테스트"
                  className="responsive-image"
                />
              </td>
              <td className="table-text">
                <h2 className="fixed-title">댕댕이 놀이터</h2>
                <ul>
                  <li>
                    <h3>
                      <span className="underline">어질리티</span>
                      <span>(Agility)</span>
                    </h3>
                    <p>
                      반려견이 보호자의 지시에 따라 장애물을 신속하고 정확하게
                      통과하는 도그스포츠
                    </p>
                  </li>
                  <li>
                    <h3>
                      <span className="underline">노즈워크</span>
                      <span>(Nosework)</span>
                    </h3>
                    <p>
                      반려견이 후각(냄새)을 활용하여 특정 물체나 보상을 찾아내는
                      도그스포츠
                    </p>
                  </li>
                  <li>
                    <h3>
                      <span className="underline">프리스비</span>
                      <span>(Frisbee) 공연</span>
                    </h3>
                    <p>
                      원반을 던져서 땅에 떨어지기 전에 개가 물어서 사람에게
                      가져오게 하는 도그스포츠
                    </p>
                  </li>
                  <li>
                    <h3>
                      <span className="underline">경찰견</span>
                      <span>(Police Dog) 시범</span>
                    </h3>
                    <p>
                      훈련된 경찰견이 범죄 현장이나 수색 작업에서 어떻게
                      활약하는지 볼 수 있습니다.
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <div style={{ marginTop: "50px" }}></div>
        <div className="tables-container">
          <table className="zone-table">
            <tbody>
              <tr>
                <th colSpan="2">상담존</th>
              </tr>
              <tr>
                <td className="table-text">
                  <h3>
                    <span className="underline">반려견</span>
                    <span> 훈련</span>
                  </h3>
                </td>
                <td className="table-text">
                  <h3>
                    <span className="underline">반려동물</span>
                    <span className="mobileSpan"> 매개치료</span>
                  </h3>
                </td>
              </tr>
              <tr>
                <td className="table-text">
                  <h3>
                    <span className="underline">반려동물</span>
                    <span className="mobileSpan">학과 진학</span>
                  </h3>
                </td>
                <td className="table-text">
                  <h3>
                    <span className="underline">유기동물</span>
                    <span className="mobileSpan"> </span>
                  </h3>
                </td>
              </tr>
            </tbody>
          </table>
          {/* <div style={{ marginTop: "50px" }}></div> */}
          <table className="zone-table">
            <tbody>
              <tr>
                <th colSpan="2">체험존</th>
              </tr>
              <tr>
                <td className="table-text">
                  <h3>
                    <span className="underline">반려동물</span>
                    <span className="mobileSpan"> 미용</span>
                  </h3>
                </td>
                <td className="table-text">
                  <h3>
                    <span className="underline">펫 아로마</span>
                    <span className="mobileSpan">오일 만들기</span>
                  </h3>
                </td>
              </tr>
              <tr>
                <td className="table-text">
                  <h3>
                    <span className="underline">애견수제간식</span>
                    <span className="mobileSpan">만들기</span>
                  </h3>
                </td>
                <td className="table-text">
                  <h3 className="underline">플리마켓</h3>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ marginTop: "100px" }}></div>
        <div className="caniwalingRule" id="target-element">
          {/* <img
            src={캐니워킹규정}
            alt="캐니워킹규정"
            className="caniwalingRule"
            id="target-element"
          /> */}
          <FullscreenModal image={캐니워킹규정} />
        </div>

        <div style={{ marginTop: "100px" }}></div>
      </div>
    </>
  );
};

export default Program;
