import React, { useEffect, useState } from "react";
import axios from "axios";
import 확인버튼 from "../assets/images/reserve/check_button.png";

const AdminDashboard = () => {
  const [reservations, setReservations] = useState([]);
  const [error, setError] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);

  const [password, setPassword] = useState("");
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordSubmit = () => {
    // Replace 'your_password' with the actual correct password
    const correctPassword = process.env.REACT_APP_ADMIN_PASSWORD;

    if (password === correctPassword) {
      setIsPasswordCorrect(true);
    } else {
      alert("Incorrect password. Please try again.");
      setPassword("");
    }
  };

  const handleKeyPress = (e) => {
    // 엔터 키가 눌렸을 때
    if (e.key === "Enter") {
      handlePasswordSubmit();
    }
  };

  // 예약 데이터 가져오기
  useEffect(() => {
    const fetchReservations = async () => {
      try {
        const response = await axios.get(
          "https://k-dogs.co.kr/admin/reservations",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setReservations(response.data);
      } catch (err) {
        setError("Failed to fetch reservations");
      }
    };
    fetchReservations();
  }, []);

  const downloadCSV = async () => {
    try {
      const response = await axios.get(
        "https://k-dogs.co.kr/api/download-csv",
        {
          responseType: "blob", // 응답을 blob으로 받음 (파일 다운로드를 위해)
        }
      );

      // 브라우저에서 파일을 다운로드
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "reservations.csv"); // 다운로드 파일명 설정
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading CSV:", error);
      alert("CSV 파일 다운로드 중 문제가 발생했습니다.");
    }
  };

  return (
    <div className="main">
      <div>
        {isPasswordCorrect ? (
          <div className="admin-dashboard">
            <h1>Admin Dashboard</h1>

            {/* <button onClick={downloadCSV}>Download Excel</button> */}
            <div style={{ marginTop: "50px" }}></div>
            <button
              onClick={downloadCSV}
              style={{
                padding: "12px 24px",
                fontSize: "16px",
                color: "#ffffff",
                backgroundColor: "#007bff", // 기본 컬러
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
                fontWeight: "bold",
                letterSpacing: "0.5px",
                transition: "background-color 0.3s ease, transform 0.2s",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#0056b3"; // 더 어두운 파란색
                e.target.style.transform = "scale(1.05)"; // 살짝 커짐 효과
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#007bff";
                e.target.style.transform = "scale(1)"; // 원래 크기로
              }}
            >
              Download Excel
            </button>
            <div style={{ marginTop: "100px" }}></div>
          </div>
        ) : (
          // Password input form
          <div>
            <h1>Enter Password to Access</h1>
            <span>Password : </span>
            <input
              style={{
                width: "150px",
                marginBottom: "30px",
                marginTop: "20px",
              }}
              type="password"
              value={password}
              onChange={handlePasswordChange}
              onKeyDown={handleKeyPress}
            />

            <span style={{ marginLeft: "20px" }}></span>
            <button
              onClick={handlePasswordSubmit}
              style={{
                padding: "12px 24px",
                fontSize: "16px",
                color: "#ffffff",
                backgroundColor: "#007bff", // 기본 컬러
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
                fontWeight: "bold",
                letterSpacing: "0.5px",
                transition: "background-color 0.3s ease, transform 0.2s",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#0056b3"; // 더 어두운 파란색
                e.target.style.transform = "scale(1.05)"; // 살짝 커짐 효과
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#007bff";
                e.target.style.transform = "scale(1)"; // 원래 크기로
              }}
            >
              확인
            </button>
            <div style={{ marginTop: "100px" }}></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
