// CustomModal.js
import React from "react";
import Modal from "react-modal";
import "./Home.css"; // 필요한 경우 커스텀 스타일을 가져옵니다.

const CustomModal = ({
  isOpen,
  onRequestClose,
  closeModal,
  downloadLogo,
  popupClose,
  playstoreLink,
  appstoreLink,
  onClick,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Example Modal"
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <img
        className="popupCloseBtn"
        src={popupClose}
        alt="popup_close"
        onClick={closeModal}
      />
      <img src={downloadLogo} alt="download_logo" className="download-logo" />
      <div className="button-container">
        <img
          src={playstoreLink}
          alt="playstore_btn"
          className="store-btn"
          onClick={() =>
            window.open(
              "https://play.google.com/store/apps/details?id=metadogs.kr&hl=ko"
            )
          }
        />
        <img
          src={appstoreLink}
          alt="appstore_btn"
          className="store-btn"
          onClick={() =>
            window.open(
              "https://apps.apple.com/kr/app/k-dog/id6630378405?l=en-GB"
            )
          }
        />
      </div>

      <div className="caniMoreInfoModal" onClick={onClick}>
        ▼ 캐니워킹 알아보기 ▼
        <div
          style={{
            marginTop: "10px",
            fontSize: "0.7em",
            color: "blue",
            textAlign: "center",
          }}
        >
          Click
        </div>
      </div>
    </Modal>
  );
};

export default CustomModal;
