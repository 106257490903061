import React, { useEffect, useState } from "react";
import poster from "../assets/images/poster.jpg"; // 이미지 경로를 import
import "./Home.css";
import rule1 from "../assets/images/안내수칙.jpg";
import rule2 from "../assets/images/관람수칙.jpg";

import 중단부분행사안내 from "../assets/images/home/중단부분행사안내.png";
// import 페스티벌알아보기 from "../assets/images/home/페스티벌알아보기.png";
import 페스티벌알아보기 from "../assets/images/home/페스티벌알아보기_일산_1029.png";
// import 포스터 from "../assets/images/home/k-dog포스터.jpg";
import 포스터 from "../assets/images/home/k-dog포스터_일산_최종.jpg";
import 주의사항 from "../assets/images/home/주의사항.png";
import 배치도 from "../assets/images/home/배치도_일산.jpg";
import Map from "./Map.jsx";
import NaverMap from "./NaverMap.js";
import Modal from "react-modal";

import FullscreenModal from "./FullscreenModal.js";
import Map_ilsanhosu from "./Map_ilsanhosu.jsx";
import 참가신청하기버튼 from "../assets/images/reserve/참가신청하기버튼_pc.png";
import { Link, useNavigate } from "react-router-dom";
// App 팝업이미지
import downloadLogoImg from "../assets/images/home/download_logo.png";
import appstoreImg from "../assets/images/home/appstore_btn.png";
import playstoreImg from "../assets/images/home/playstore_btn.png";
import download_back from "../assets/images/home/download_back.png";
import popupCloseImg from "..//assets/images/home/popup_close.png";
import CustomModal from "./CustomModal.js";
import CustomModal2 from "./CustomModal2.js";

Modal.setAppElement("#root"); // 앱의 루트 요소를 지정

const Event2_ilsan2411 = () => {
  const [modal1IsOpen, setModal1IsOpen] = useState(false);
  const [modal2IsOpen, setModal2IsOpen] = useState(false);

  const openModal1 = () => setModal1IsOpen(true);
  const closeModal1 = () => setModal1IsOpen(false);

  const openModal2 = () => setModal2IsOpen(true);
  const closeModal2 = () => setModal2IsOpen(false);

  useEffect(() => {
    // console.log("Modal useEffect triggered"); // 콘솔 로그 추가
    setModal1IsOpen(true);
    setModal2IsOpen(true);
    // 팝업내리기
  }, []);

  const navigate = useNavigate();

  const handleClick = () => {
    // "/program" 경로로 이동하며 해시를 추가합니다.
    navigate("/program#target-element");
  };

  return (
    <>
      <div className="bodytop-text">
        {/* <div className="sub-text">Contents Information</div> */}
        {/* <div className="header-text">
          <span className="underline">K-Dog 페스</span>
          <span>티벌 행사 안내</span>
        </div> */}
      </div>
      {/* <button onClick={openModal}>sdaf</button> */}
      <div>
        <CustomModal
          isOpen={modal1IsOpen}
          onRequestClose={closeModal1}
          closeModal={closeModal1}
          downloadLogo={downloadLogoImg}
          popupClose={popupCloseImg}
          playstoreLink={playstoreImg}
          appstoreLink={appstoreImg}
          onClick={handleClick}
        />
        {/* <CustomModal2
          isOpen={modal2IsOpen}
          onRequestClose={closeModal2}
          closeModal={closeModal2}
          modalImage={포스터}
          popupClose={popupCloseImg}
          onClick={handleClick}
        /> */}
      </div>

      <div className="main">
        <div className="image-section">
          <img
            src={페스티벌알아보기}
            alt="페스티벌알아보기"
            className="responsive-image"
          />
          <div className="event-info">
            <table className="info-table">
              <tr>
                <th rowSpan="6" style={{ width: "40%" }}>
                  {/* <img
                    src={포스터}
                    alt="페스티벌알아보기"
                    className="responsive-image"
                    style={{ cursor: "pointer" }}
                    // onClick={() => window.open(포스터)}
                    onClick={openModal}
                  /> */}
                  <FullscreenModal image={포스터} />
                </th>
              </tr>
              <tr>
                <td colSpan="2" className="h2title">
                  <h2>2024 K-Dog 스포츠 페스티벌</h2>
                </td>
              </tr>
              <tr>
                <td className="table-bold">일 자</td>
                <td className="table-normal">
                  2024. 11. 16(토)
                  <span className="tableDateSpan">(1일간)</span>
                </td>
              </tr>
              <tr>
                <td className="table-bold">장 소</td>
                <td className="table-normal">일산문화광장</td>
              </tr>
              <tr>
                <td className="table-bold">시 간</td>
                <td className="table-normal">10 : 00 ~ 16 : 00</td>
              </tr>
              <tr className="bottomTr">
                <td className="table-bold-bottom">가 격</td>
                <td className="table-normal-bottom">무료</td>
                {/* <td className="table-bold">가 격</td>
                <td className="table-normal">무료</td> */}
              </tr>
            </table>
            <div style={{ marginTop: "10%" }}></div>

            <Link to="/reserve/eventform">
              {/* 사전예약 */}
              <img
                src={참가신청하기버튼}
                alt="참가신청하기버튼"
                className="company-apply-button"
              />
            </Link>
            <div style={{ marginBottom: "10%" }}></div>
            <div className="info-table-shadow">
              {/* <img
                src={배치도}
                alt="배치도"
                className="responsive-image"
                style={{ cursor: "pointer" }}
                onClick={() => window.open(배치도)}
              /> */}
              <FullscreenModal image={배치도} />
            </div>

            <div className="rule-div">
              <img
                src={rule1}
                alt="애견동반수칙"
                className="responsive-image-rule"
              />
              <p></p>
              <img
                src={rule2}
                alt="관람수칙"
                className="responsive-image-rule"
              />
            </div>

            {/* 주의사항 */}
            {/* <div className="notice-text">
              <table>
                <tr>
                  <th className="notice-th">주의사항</th>
                  <tr>
                    <td className="notice-td">
                      1. 무료 입장권으로 별도의 취소/환불 절차는 진행되지
                      않습니다.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      2. 무료 사전등록 시 전시기간 2일 무료 입장 가능합니다.
                      <div>(바코드는 본인에 한해 1인 1일 1회 사용 가능)</div>
                    </td>
                  </tr>
                </tr>
              </table>
            </div> */}
            <div className="placeinfo-div"></div>
            <h1>행사장소</h1>
            <div className="border-line"></div>
            <div className="placeaddress">
              <div>주소 : 경기 고양시 일산동구 장항동 845, 일산문화광장 </div>
              {/* <div>주차장 안내: 일산호수공원 주차장</div> */}
            </div>
          </div>
          <p></p>
          <span style={{ color: "gray", marginTop: "100px" }}>
            {/* ※ 혼잡이 예상되오니 대중교통 이용바랍니다. */}
          </span>
          <p></p>
        </div>

        <Map_ilsanhosu />
        {/* <NaverMap /> */}
      </div>
    </>
  );
};

export default Event2_ilsan2411;
