import React from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
// import "./styles/App.css";
import Header from "./components/Header";
import Home from "./components/Home";
import Notice from "./components/Notice";
import Program from "./components/Program";
import Footer from "./components/Footer";
import Company from "./components/Company";
import Reservation from "./components/Reservation";
import "./styles/App.css";
import EventForm from "./components/EventForm";
import Event1_gangreung2410 from "./components/Event1_gangreung2410";
import Event2_ilsan2411 from "./components/Event2_ilsan2411";
import GangreungHeader from "./components/GangreungHeader";
import GangreungFooter from "./components/GangreungFooter";
import AdminLogin from "./components/AdminLogin";
import AdminDashboard from "./components/AdminDashboard";
import SuccessPage from "./components/SuccessPage";
import EventCheck from "./components/EventCheck";

// Header 선택 컴포넌트
function HeaderSelector() {
  const location = useLocation();

  // URL이 /gangreung으로 시작하는 경우 GangreungHeader 컴포넌트 렌더링
  if (location.pathname.startsWith("/gangreung")) {
    return <GangreungHeader />;
  }

  // 그 외에는 기본 Header 컴포넌트 렌더링
  return <Header />;
}

// Header 선택 컴포넌트
function FooterSelector() {
  const location = useLocation();

  // URL이 /gangreung으로 시작하는 경우 GangreungHeader 컴포넌트 렌더링
  if (location.pathname.startsWith("/gangreung")) {
    return <GangreungFooter />;
  }

  // 그 외에는 기본 Header 컴포넌트 렌더링
  return <Footer />;
}
function App() {
  return (
    <div className="App">
      <BrowserRouter basename="/">
        {/* Header 선택 */}
        <HeaderSelector />
        <Routes>
          <Route path="/login" element={<AdminLogin />} />
          {/* <Route path="/" element={<Home />} /> */}
          <Route
            path="/gangreung/Event1_gangreung2410"
            element={<Event1_gangreung2410 />}
          />
          <Route path="/" element={<Event2_ilsan2411 />} />
          <Route path="/program" element={<Program />} />
          <Route path="/notice" element={<Notice />} />
          <Route path="/reserve/company" element={<Company />} />
          <Route path="/reserve/reservation" element={<Reservation />} />
          <Route path="/reserve/eventcheck" element={<EventCheck />} />
          <Route path="/reserve/eventform" element={<EventForm />} />
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/reserve/success" element={<SuccessPage />} />
        </Routes>
        <FooterSelector />
      </BrowserRouter>
    </div>
  );
}

export default App;
