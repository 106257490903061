import React, { useState, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "./Company.css";
import { useForm } from "react-hook-form";
import 예약하기_활성 from "../assets/images/reserve/예약하기_활성.jpg";
import 예약하기_비활성 from "../assets/images/reserve/예약하기_비활성.jpg";
import 예약하기버튼 from "../assets/images/reserve/예약하기버튼.png";

import 신청하기버튼 from "../assets/images/reserve/신청하기버튼.png";

const mixBreeds = ["", "믹스"];
// const mixBreeds = ["믹스"];

const smallBreeds = [
  "아펜핀셔",
  "오스트레일리안 테리어",
  "비숑 프리제",
  "보스턴테리어",
  "브뤼셀 그리펀",
  "프랜치불독",
  "케언 테리어",
  "치와와",
  "차이니스 크레스티드",
  "요크셔테리어",
  "닥스훈트",
  "댄디 딘몬트 테리어",
  "이탈리안 그레이하운드",
  "잭 러셀 테리어",
  "푸들",
  "퍼그",
  "레이크랜드 테리어",
  "라사압소",
  "몰티즈(말티즈)",
  "맨체스터 테리어",
  "미니어처 슈나우져",
  "노르포크테리",
  "노르위차태리어",
  "파피용",
  "페키니즈",
  "포메라니안",
  "시츄",
  "실키테리어",
  "스코티쉬 테리어",
  "실리함 테리어",
  "웰쉬 테리어",
  "웨스트 하이랜드 화이트 테리어",
  "스카이 테리어",
  "티벳탄 스파니엘",
  "토이 멘체스터 테리어",
];

const mediumBreeds = [
  "에어데일 테리어",
  "아메리칸 코카 스페니엘",
  "바센지",
  "바셋하운드",
  "비글",
  "베드링턴 테리어",
  "보더콜리",
  "브리타나",
  "불독",
  "차우차우",
  "잉글리쉬 코카 스파니엘",
  "아이리쉬 테리어",
  "진돗개",
  "케리 블루 테리어",
  "노르웨이언 엘크 하운드",
  "풀리",
  "셔틀랜드 쉽독",
  "시바 이누",
  "소프트 코티드 휘튼 테리어",
  "스테포드셔 불 테리어",
  "서섹스 스파니엘",
  "티멧탄 테리어",
  "웰쉬코기",
  "휘펫",
  "차이니즈 샤페이",
  "제페니스 친",
  "재패니즈 스피츠",
];

const largeBreeds = [
  "아프간하운드",
  "아키다",
  "알래스칸 맬러뮤트",
  "비어디드 콜리",
  "벨지안 쉽도그 그로넨달",
  "벨지안 쉽도그 터뷰렌",
  "버니즈 마운틴 독",
  "블랙 앤 탄 쿤하운드",
  "블러드 하운드",
  "보르조이",
  "복서",
  "그럼버 스파니엘",
  "콜리",
  "컬리 코티드 리트리버",
  "달마시안",
  "도베르만 핀셔",
  "잉글리쉬 세터",
  "잉글리쉬 스프링거 스파니엘",
  "글랫-코티드 리트리버",
  "저먼 셰퍼드 독",
  "자이언트 슈나우저",
  "골든리트리버",
  "그레이하운드",
  "아이리쉬 세터",
  "아이리쉬 워터 스파니엘",
  "래브라도 리트리버",
  "포인터",
  "풍산개",
  "로디지안 리즈백",
  "살루키",
  "사모예드",
  "시베리안 허스키",
  "비즐라",
  "스텐다드 푸들",
];

const sortedBreeds = [
  ...smallBreeds.map((breed) => ({ name: breed, size: "10kg 미만" })),
  ...mediumBreeds.map((breed) => ({ name: breed, size: "10-20kg" })),
  ...largeBreeds.map((breed) => ({ name: breed, size: "20kg 초과" })),
].sort((a, b) => a.name.localeCompare(b.name)); // 이름을 기준으로 정렬

const allBreeds = [
  ...mixBreeds.map((breed) => ({ name: breed, size: "믹스" })), // 믹스 항목이 먼저 오도록 추가
  ...sortedBreeds, // 정렬된 나머지 품종들
];

const EventForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    sex: "",
    age: "",
    email: "",
    phone: "",
    event: "",
    region: "",
    petname: "",
    breed: "",
    petage: "",
    neutered: "",
    vaccinated: "",
    petsize: "",
    autopetsize: "",
    agreetoterms: false, // 초기 체크박스 값
  });
  const [filteredBreeds, setFilteredBreeds] = useState(allBreeds);
  const [showDropdown, setShowDropdown] = useState(false);

  const {
    register,
    formState: { errors },
  } = useForm();

  const [isChecked, setIsChecked] = useState(false);

  // 체크박스 상태를 변경하는 함수
  const handleCheckboxChange = (event) => {
    const { checked } = event.target;

    // 체크박스 값도 formData에 추가
    setIsChecked(checked);
    setFormData({
      ...formData,
      agreetoterms: checked, // 체크박스의 체크 상태를 formData에 저장
    });
  };

  const formRef = useRef(null); // form 참조 생성
  const navigate = useNavigate(); // useNavigate 훅

  const handleChange = (e) => {
    const { name, value } = e.target;

    // 주소 필드에서는 공백을 허용하고, 다른 필드에서는 공백을 제거
    setFormData({
      ...formData,
      [name]: name === "region" ? value : value.replace(/\s+/g, ""), // 'region' 필드만 공백을 허용
    });
  };

  // // formData와 체크박스가 모두 유효할 때만 예약 버튼을 활성화
  // const isFormValid =
  //   formData.name &&
  //   formData.sex &&
  //   formData.age &&
  //   formData.email &&
  //   formData.phone &&
  //   formData.event &&
  //   formData.region &&
  //   formData.petname &&
  //   formData.breed &&
  //   formData.petage &&
  //   formData.neutered &&
  //   formData.vaccinated &&
  //   formData.petsize &&
  //   formData.autopetsize &&
  //   formData.agreetoterms; // 체크박스 값도 formData에 반영
  // 초성 추출 함수
  const getChosung = (str) => {
    const cho = [
      "ㄱ",
      "ㄲ",
      "ㄴ",
      "ㄷ",
      "ㄸ",
      "ㄹ",
      "ㅁ",
      "ㅂ",
      "ㅃ",
      "ㅅ",
      "ㅆ",
      "ㅇ",
      "ㅈ",
      "ㅉ",
      "ㅊ",
      "ㅋ",
      "ㅌ",
      "ㅍ",
      "ㅎ",
    ];

    return Array.from(str)
      .map((char) => {
        const code = char.charCodeAt(0) - 0xac00;
        if (code >= 0 && code <= 11172) {
          return cho[Math.floor(code / 588)];
        }
        return char; // 초성 변환 불가시 원래 문자 반환
      })
      .join("");
  };

  const handleBreedChange = (e) => {
    const input = e.target.value.toLowerCase(); // 소문자로 변환하여 검색
    setFormData({ ...formData, breed: input });

    if (input === "") {
      setFilteredBreeds(allBreeds); // 빈 문자열일 경우 모든 항목 표시
    } else {
      const filtered = allBreeds.filter((breed) => {
        const breedName = breed.name.toLowerCase();
        const breedChosung = getChosung(breedName);
        return (
          breedName.includes(input) || // 이름으로 검색
          breedChosung.includes(input) // 초성으로 검색
        );
      });
      setFilteredBreeds(filtered);
    }
    setShowDropdown(true);
  };

  const handleBreedSelect = (breed) => {
    if (breed.name === "직접입력") {
      // 빈 문자열로 설정하여 사용자가 직접 입력할 수 있도록 만듦
      setFormData({ ...formData, breed: "" });
    } else {
      setFormData({ ...formData, breed: breed.name, autopetsize: breed.size });
    }
    setShowDropdown(false);
  };
  const handleFocus = () => {
    setShowDropdown(true);
  };

  const handleBlur = () => {
    // 드롭다운이 사라지게 하기 위해 딜레이를 주어 클릭 이벤트가 먼저 발생하도록 합니다.
    setTimeout(() => setShowDropdown(false), 200);
  };

  // 예약 버튼 활성화 조건
  const isFormValid =
    Object.values(formData).every((value) => value) && isChecked;

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form data being submitted:", formData);

    if (!isFormValid) {
      alert("모든 필드를 입력하고 동의 체크박스를 선택해주세요.");
      return;
    }

    try {
      const response = await axios.post(
        "https://k-dogs.co.kr/api/reservations",
        // "http://localhost:5000/api/reservations",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        alert("예약신청이 완료되었습니다.");
        setFormData({
          name: "",
          email: "",
          age: "",
          phone: "",
          event: "",
          region: "",
          petname: "",
          breed: "",
          petage: "",
          neutered: "",
          vaccinated: "",
          petsize: "",
          autopetsize: "",
          agreetoterms: "",
        });
        setIsChecked(false);

        // 성공적으로 예약이 완료되면 '/success' 페이지로 리다이렉트
        navigate("/reserve/success");
      } else {
        alert("예약을 저장하는 중 문제가 발생했습니다.");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        alert("신청이 마감되었습니다. 더 이상 신청할 수 없습니다.");
      } else {
        console.error("Error during submission:", error);
        alert("예약을 저장하는 중 문제가 발생했습니다.");
      }
    }
  };
  return (
    <>
      <div className="bodytop-text">
        {/* <div className="sub-text">Contents Information</div> */}
        {/* <div className="header-text">
          <span className="underline">K-Dog 스포츠</span>
          <span> 고양 사전예약</span>
        </div> */}
      </div>

      <div className="main">
        <p></p>
        <div className="form-container">
          <div className="informationTextForm">
            <h2>
              K-DOG SPORTS FESTIVAL
              <div>캐니워킹 참가 안내사항</div>
            </h2>

            <p>
              캐니워킹에 참여하는 모든 분들이 안전하고 즐거운 시간을 보낼 수
              있도록 아래 주의 사항을 꼭 지켜주세요.
            </p>
            <ul>
              <li>참가자 1인당 반려견 한 마리만 동반해 주세요.</li>
              <li>
                반려견을 안거나, 이동가방 또는 개모차를 사용하는 것은 금지되어
                있습니다.
              </li>
              <li>
                안전을 위해 자동 리드줄은 사용이 불가하며, 2m 이하의 고정
                리드줄을 사용해 주세요.
              </li>
              <li>
                사람들이나 다른 반려견이 많은 장소에서는 리드줄을 1m 이하로
                유지해 주세요.
              </li>
              <li>
                다른 사람이나 반려동물에게 위협을 줄 수 있는 경우 반드시
                입마개를 착용해 주세요.
              </li>
              <li>3차 예방접종 및 광견병 예방접종을 완료해 주세요.</li>
              <li>보호자와 반려견의 건강 상태를 확인한 후 참가해 주세요.</li>
              <li>법적으로 지정된 맹견류는 참가할 수 없습니다.</li>
              <li>생리 중인 반려견은 참가를 자제해 주세요.</li>
              <li>
                반려견 관리 소홀로 발생하는 모든 문제는 보호자가 책임을 지게
                됩니다.
              </li>
            </ul>
            <p>
              안전한 페스티벌을 위해 모든 참가자분들의
              <div>협조를 부탁드립니다!</div>
            </p>
          </div>

          <form ref={formRef} onSubmit={handleSubmit}>
            <div className="totalFormDiv"></div>
            <label className="inputLabel">
              <div className="textDiv">신청자명</div>
              <input
                type="text"
                name="name"
                placeholder="예) 홍길동"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </label>
            <label className="inputLabel">
              <div className="textDiv">성별</div>
              <select
                name="sex"
                value={formData.sex}
                onChange={handleChange}
                required
              >
                <option value="">-- 성별을 선택해주세요 --</option>
                <option value="man">남자</option>
                <option value="woman">여자</option>
              </select>
            </label>

            <label className="inputLabel">
              <div className="textDiv">연령대</div>
              <select
                name="age"
                value={formData.age}
                onChange={handleChange}
                required
              >
                <option value="">-- 연령을 선택해주세요 --</option>
                <option value="10">10대</option>
                <option value="20">20대</option>
                <option value="30">30대</option>
                <option value="40">40대</option>
                <option value="50">50대</option>
                <option value="60">60대</option>
                <option value="70">70대 이상</option>
              </select>
            </label>
            <label className="inputLabel">
              <div className="textDiv">이메일</div>
              <input
                type="email"
                name="email"
                placeholder="예) test@email.com"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </label>
            <label className="inputLabel">
              <div className="textDiv">전화번호</div>
              <input
                type="phone"
                name="phone"
                placeholder="예) 01011112222"
                value={formData.phone}
                onChange={handleChange}
                required
              />
              <small>숫자만 입력해주세요.</small>
            </label>
            <label className="inputLabel">
              <div className="textDiv">사는곳</div>
              <input
                type="region"
                name="region"
                placeholder="예) 경기도 고양시 일산서구 주엽동 (동까지 입력해주세요)"
                value={formData.region}
                onChange={handleChange}
                required
              />
            </label>
            {/* 반려동물 이름 */}
            <label className="inputLabel">
              <div className="textDiv">반려동물 이름</div>
              <input
                type="text"
                name="petname"
                value={formData.petname}
                onChange={handleChange}
                placeholder="반려동물 이름"
                required
              />
            </label>
            {/* 반려동물 품종 */}
            {/* <label className="inputLabel">
              <div className="textDiv">반려동물 품종</div>
              <input
                type="text"
                name="breed"
                value={formData.breed}
                onChange={handleChange}
                placeholder="예) 푸들, 비글"
                required
              />
            </label> */}

            <label className="inputLabel">
              <div className="textDiv">반려동물 품종</div>
              <input
                type="text"
                name="breed"
                value={formData.breed}
                onChange={handleBreedChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder="품종을 입력해주세요."
                required
                className="breedSelect"
                style={{ cursor: "pointer" }}
              />
              {showDropdown && (
                <div className="breedDropdown">
                  {filteredBreeds.map((breed) => (
                    <div
                      key={breed.name}
                      className="breedOption"
                      onClick={() => handleBreedSelect(breed)}
                    >
                      {breed.name === "" ? "직접입력" : breed.name}
                      {/* {breed.name} */}
                    </div>
                  ))}
                </div>
              )}
            </label>

            {/* 반려동물 나이 */}
            <label className="inputLabel">
              <div className="textDiv">반려동물 나이</div>
              <input
                type="number"
                name="petage"
                value={formData.petage}
                onChange={handleChange}
                placeholder="나이 (숫자 입력)"
                required
              />
            </label>
            {/* 중성화 여부 */}

            <label className="fieldsetLabel">
              <div className="textDiv">중성화 여부</div>

              <fieldset className="filedset">
                <p></p>
                <label className="checkboxLabel">
                  <input
                    className="checkboxLabelInput"
                    type="radio"
                    name="neutered"
                    value="O"
                    checked={formData.neutered === "O"}
                    onChange={handleChange}
                    required
                  />

                  <div className="petSizeText">O</div>
                </label>
                <div></div>
                <label className="checkboxLabel">
                  <input
                    className="checkboxLabelInput"
                    type="radio"
                    name="neutered"
                    value="X"
                    checked={formData.neutered === "X"}
                    onChange={handleChange}
                  />
                  <div className="petSizeText">X</div>
                </label>
              </fieldset>
            </label>
            {/* 예방접종 여부 */}
            <label className="fieldsetLabel">
              <div className="textDiv">예방접종 여부</div>
              <fieldset className="filedset">
                <p></p>
                {/* <legend className="legendLabel">
                  <div className="textDivLegend">예방접종 여부</div>
                </legend> */}
                <label className="checkboxLabel">
                  <input
                    className="checkboxLabelInput"
                    type="radio"
                    name="vaccinated"
                    value="O"
                    checked={formData.vaccinated === "O"}
                    onChange={handleChange}
                    required
                  />
                  <div className="petSizeText">O</div>
                </label>
                <div></div>
                <label className="checkboxLabel">
                  <input
                    className="checkboxLabelInput"
                    type="radio"
                    name="vaccinated"
                    value="X"
                    checked={formData.vaccinated === "X"}
                    onChange={handleChange}
                  />

                  <div className="petSizeText">X</div>
                </label>
              </fieldset>
            </label>
            {/* 반려동물 크기 */}
            <label className="fieldsetLabel">
              <div className="textDiv">반려동물 크기</div>
              <div className="fieldset"></div>
              <fieldset className="filedset">
                <p></p>
                <label className="checkboxLabel">
                  <input
                    className="checkboxLabelInput"
                    type="radio"
                    name="petsize"
                    value="small"
                    checked={formData.petsize === "small"}
                    onChange={handleChange}
                    required
                  />
                  <div className="petSizeText">소형견 (10kg 미만)</div>
                </label>
                <div></div>

                <label className="checkboxLabel">
                  <input
                    className="checkboxLabelInput"
                    type="radio"
                    name="petsize"
                    value="medium"
                    checked={formData.petsize === "medium"}
                    onChange={handleChange}
                  />
                  <div className="petSizeText">중형견 (10kg ~ 25kg 미만)</div>
                </label>
                <div></div>

                <label className="checkboxLabel">
                  <input
                    className="checkboxLabelInput"
                    type="radio"
                    name="petsize"
                    value="large"
                    checked={formData.petsize === "large"}
                    onChange={handleChange}
                  />

                  <div className="petSizeText">대형견 (25kg 이상)</div>
                </label>
                {/* <div className="filedset">25kg 이상</div> */}
                <div></div>
              </fieldset>
              <label className="selectLabel">
                <div className="textDiv">행사 선택:</div>
                <select
                  name="event"
                  value={formData.event}
                  onChange={handleChange}
                  required
                >
                  <option value="">-- 행사를 선택하세요 --</option>
                  <option value="캐니워킹">캐니워킹</option>
                </select>
              </label>
            </label>

            <p></p>
            <label style={{ marginTop: "70px", marginBottom: "30px" }}>
              개인정보 수집 및 이용 동의
            </label>
            <textarea
              className="formTextArea"
              defaultValue={`개인정보 수집 및 이용 동의 \n
  1. 개인정보 수집 항목 수집하는 정보: 이름, 연락처(전화번호, 이메일), 주소, 반려동물 정보(이름, 종류, 품종, 나이, 성별), 참가 희망 종목 \n
  2. 개인정보 수집 및 이용 목적 - 행사 신청 접수 및 참가 확인 - 행사 관련 공지사항 전달 및 문의 응대 - 행사 진행 및 참여자 관리 - 안전 사고 방지를 위한 기본 정보 확보 \n
  3. 개인정보 보유 및 이용 기간 수집된 개인정보는 행사 종료 후 1년 동안 보관되며, 이후 해당 정보를 파기합니다. 다만, 관련 법령에 의해 보관해야 하는 경우 해당 법령에 따릅니다. \n
  4. 개인정보 제3자 제공 신청자의 동의 없이는 수집된 개인정보를 제3자에게 제공하지 않습니다. 다만, 법적 의무가 있거나 참가자의 사전 동의를 받은 경우에 한해 제공될 수 있습니다. \n
  5. 개인정보 처리 위탁 원활한 행사 운영을 위해 일부 정보를 행사 대행 업체에 위탁할 수 있으며, 해당 업체는 개인정보 보호법을 준수합니다. \n
  6. 동의 거부 권리 및 불이익 개인정보 수집 및 이용 대한 동의를 거부할 수 있으나, 이 경우 행사 참여가 제한될 수 있습니다.`.replace(
                /\n/g,
                "\n"
              )}
            />

            <div>
              <label className="checkboxLabelAgree">
                <input
                  className="agreeCheckbox"
                  type="checkbox"
                  name="agree"
                  value={formData.agreetoterms}
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <span>동의하기</span>
              </label>
              {isChecked ? (
                <div style={{ color: "blue" }}>
                  개인정보 이용약관에 동의하며 안내사항을 모두 확인하였습니다.
                </div>
              ) : (
                <div style={{ color: "red" }}>
                  안내사항과 이용약관을 확인해주세요.
                </div>
              )}
            </div>

            <button
              type="submit"
              // disabled={!isFormValid}
              style={{
                background: "none",
                border: "none",
                padding: 0,
                outline: "none",
                cursor: "pointer",
                zIndex: "999",
              }}
              onClick={() => console.log("submit button")}
            >
              {/* 예약하기 */}

              <img
                src={신청하기버튼}
                alt="신청하기버튼"
                className="reserveButton"
              />
            </button>
            <div style={{ marginBottom: "150px" }}></div>
            {/* 
            <div className="button-container">
              {!isFormValid ? (
                <img
                  src={예약하기_비활성}
                  alt="비활성"
                  className="applyButton"
                  onClick={() =>
                    alert("모든 필드를 입력하고 동의 체크박스를 선택해주세요.")
                  }
                />
              ) : (
                <img
                  src={예약하기_활성}
                  alt="활성"
                  className="applyButton"
                  onClick={() => formRef.current.submit()} // 클릭 시 form을 제출
                />
              )}
            </div> */}
          </form>
        </div>
      </div>
    </>
  );
};

export default EventForm;
