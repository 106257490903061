import React, { useEffect } from "react";
import "./Home.css";

const Map = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_JAVA_SCRIPT_KEY}&autoload=false&libraries=services`;
    script.async = true;
    script.onload = () => {
      window.kakao.maps.load(() => {
        const container = document.getElementById("map");
        const options = {
          center: new window.kakao.maps.LatLng(
            37.65653425581939,
            126.7654877348696
          ),
          level: 3,
        };

        const mapTypeControl = new window.kakao.maps.MapTypeControl();

        const map = new window.kakao.maps.Map(container, options);

        map.addControl(
          mapTypeControl,
          window.kakao.maps.ControlPosition.TOPRIGHT
        );

        var zoomControl = new window.kakao.maps.ZoomControl();
        map.addControl(zoomControl, window.kakao.maps.ControlPosition.RIGHT);

        // 마커를 생성합니다.
        const markerPosition = new window.kakao.maps.LatLng(
          37.65653425581939,
          126.7654877348696
        );
        const marker = new window.kakao.maps.Marker({
          position: markerPosition,
        });

        // 마커가 지도 위에 표시되도록 설정합니다.
        marker.setMap(map);

        // 위도, 경도 확인하기
        // 지도에 클릭 이벤트를 등록합니다
        window.kakao.maps.event.addListener(
          map,
          "click",
          function (mouseEvent) {
            // 클릭한 위치에 마커를 표시합니다
            marker.setPosition(mouseEvent.latLng);

            // 클릭한 위치의 위도와 경도를 가져옵니다
            var latlng = mouseEvent.latLng;
            var message =
              "위도: " + latlng.getLat() + ", 경도: " + latlng.getLng();
            console.log("message:", message);

            var resultDiv = document.getElementById("coordinates");
            if (resultDiv) {
              resultDiv.innerHTML = message;
            }
          }
        );
      });
    };
    document.head.appendChild(script);
  }, []);

  return (
    <div className="kakaomap">
      <div id="map" className="map-div"></div>
    </div>
  );
};

export default Map;
