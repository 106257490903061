import React from "react";
import "./Company.css";
import { Link } from "react-router-dom";
import 확인버튼 from "../assets/images/reserve/check_button.png";

const SuccessPage = () => {
  const handleRefresh = () => {
    window.location.reload(); // 페이지 새로고침
  };

  return (
    <div className="bodytop-text">
      <div style={{ marginTop: "100px" }}></div>
      <h2>참가 신청이 성공적으로 </h2>
      <h2>완료되었습니다!</h2>
      <p>이벤트 관련 세부 사항은 문자로 안내됩니다.</p>
      {/* <button onClick={handleRefresh} className="refreshButton">
        새로고침
      </button> */}
      <Link to="/reserve/reservation">
        <img src={확인버튼} alt="확인버튼" className="checkButton" />
      </Link>
      <div style={{ marginBottom: "100px" }}></div>
    </div>
  );
};

export default SuccessPage;
