import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
// import styles from "./Header.module.css"; // CSS 모듈을 불러옵니다.
import "./Header.css"; // CSS 모듈을 불러옵니다.

import logo from "../assets/images/logo1.png"; // 로고 이미지 추가
import instagramIcon from "../assets/icon/instagram.png";
import blogIcon from "../assets/icon/blog.png";
import youtubeIcon from "../assets/icon/youtube.png";
import banner from "../assets/images/banner_ver2.png";
import dropbutton from "../assets/icon/dropdown.png";

const GangreungHeader = () => {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [eventDropdownOpen, setEventDropdownOpen] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(`url(${banner})`);
  const [activeButton, setActiveButton] = useState("");

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleDropdown = (e) => {
    e.stopPropagation(); // 이벤트 버블링 방지
    setDropdownOpen(!dropdownOpen);
  };

  const handleEventMouseEnter = () => {
    setEventDropdownOpen(true);
  };

  const handleEventMouseLeave = () => {
    setEventDropdownOpen(false);
  };

  useEffect(() => {
    setMenuOpen(false);
    setDropdownOpen(false); // 메뉴 이동 시 드롭다운 닫기
    setEventDropdownOpen(false); // 행사 안내 드롭다운도 닫기
  }, [location.pathname]);

  useEffect(() => {
    const updateBackgroundImage = () => {
      switch (location.pathname) {
        case "/":
          setBackgroundImage(`url(${banner})`);
          break;
        case "/program":
          setBackgroundImage(`url(${banner})`);
          break;
        default:
          setBackgroundImage(`url(${banner})`);
          break;
      }
    };

    updateBackgroundImage();
  }, [location.pathname]);

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  return (
    <div className="header">
      <div
        className={`overlay ${menuOpen ? "active" : ""}`}
        onClick={toggleMenu}
      ></div>

      <div className="header-container">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="댕페스타 & 펫 박람회 로고"></img>
          </Link>
        </div>
        <button className="menu-toggle" onClick={toggleMenu}>
          &#9776;
        </button>
        <div className={`header-right ${menuOpen ? "open" : ""}`}>
          <nav>
            <ul className="nav-links">
              {/* <li className={activeButton === "event" ? "active" : ""}>
                <Link
                  to="/Event1_gangreung2410"
                  onClick={() => handleButtonClick("event")}
                >
                  행사안내
                </Link>
              </li> */}
              <li
                className={activeButton === "event" ? "active" : ""}
                onMouseEnter={handleEventMouseEnter}
                onMouseLeave={handleEventMouseLeave}
              >
                <div style={{ cursor: "pointer" }}>
                  행사안내
                  <span className="dropdown-icon">
                    <img src={dropbutton} alt="dropdown" />
                  </span>
                </div>
                {eventDropdownOpen && (
                  <ul className="submenu">
                    <li>
                      <Link
                        to="/gangreung/Event1_gangreung2410"
                        onClick={() => handleButtonClick("event")}
                      >
                        K-Dog스포츠 강릉
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Event2_ilsan2411"
                        onClick={() => handleButtonClick("event")}
                      >
                        K-Dog스포츠 고양
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              <li className={activeButton === "program" ? "active" : ""}>
                <Link
                  to="/gangreung/program"
                  onClick={() => handleButtonClick("program")}
                >
                  프로그램
                </Link>
              </li>
              <li className={activeButton === "reserve" ? "active" : ""}>
                <Link
                  to="/gangreung/reserve/reservation"
                  onClick={() => handleButtonClick("reserve")}
                >
                  사전예약
                </Link>
              </li>
            </ul>
          </nav>
          <div className="sns-icons">
            <a
              href="https://www.instagram.com/k_dog_sports/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagramIcon} alt="instagram" />
            </a>
            <a
              href="https://blog.naver.com/koreadogsports"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={blogIcon} alt="blog" />
            </a>
            <a
              href="https://www.youtube.com/@Daengfesta"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={youtubeIcon} alt="youtube" />
            </a>
          </div>
        </div>
      </div>
      <div className="pairInfo">
        {/* {location.pathname === "/Event1_gangreung2410" && (
          <div className="menu-large-text">
            <div className="text-container">
              <h1 className="large-title">
                <span className="line">행사 </span>
                <span className="noline"> 안내</span>
              </h1>
              <p className="subtitle">
                댕댕이를 위한, 댕댕이에 의한 축제에 당신을 초대합니다.
              </p>
            </div>
          </div>
        )} */}
        {location.pathname === "/gangreung/Event1_gangreung2410" && (
          <div className="menu-large-text">
            <div className="text-container">
              <h1 className="large-title">
                <span className="line">행사 </span>
                <span className="noline"> 안내</span>
              </h1>
              <p className="subtitle">
                댕댕이를 위한, 댕댕이에 의한 축제에 당신을 초대합니다.
              </p>
            </div>
          </div>
        )}
        {location.pathname === "/gangreung/program" && (
          <div className="menu-large-text">
            <div className="text-container">
              <h1 className="large-title">
                <span className="line">프로그램 </span>
                <span className="noline"> 안내</span>
              </h1>
              <p className="subtitle">
                우리집 막내 댕댕이와 함께 즐기는 K-Dog 페스티벌 프로그램 안내
              </p>
            </div>
          </div>
        )}

        {(location.pathname === "/gangreung/reserve/reservation" && (
          <div className="menu-large-text">
            <div className="text-container">
              <h1 className="large-title">
                {/* <span className="line">K-Dog 페스티벌</span> */}
                <span className="noline">사전예약</span>
              </h1>
              <p className="subtitle">K-Dog 스포츠 강릉 사전예약하기</p>
            </div>
          </div>
        )) ||
          (location.pathname === "gangreung/reserve/eventform" && (
            <div className="menu-large-text">
              <div className="text-container">
                <h1 className="large-title">
                  {/* <span className="line">K-Dog 페스티벌</span> */}
                  <span className="noline">사전예약</span>
                </h1>
                <p className="subtitle">K-Dog 스포츠 강릉 사전예약하기</p>
              </div>
            </div>
          ))}

        {location.pathname === "/reserve/company" && (
          <div className="menu-large-text">
            <div className="text-container">
              <h1 className="large-title">
                <span className="line">플리마켓</span>
                <span className="noline"> 참가 신청</span>
              </h1>
              <p className="subtitle">
                제1회 K-Dog 페스티벌과 함께할 플리마켓 셀러분들을 모집합니다.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GangreungHeader;
